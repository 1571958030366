.bgImgReviewPage{
    background-image: url('https://img.freepik.com/free-photo/abstract-dark-blurred-background-smooth-gradient-texture-color-shiny-bright-website-pattern-banner-header-sidebar-graphic-art-image_1258-83074.jpg?t=st=1739100450~exp=1739104050~hmac=8e50bd4a754d023b001052f8a76f0b9388f558dabf861bf5a03f00290affa4f8&w=1380');
    background-position: left; 
    position: relative;
    height: 30rem;
    opacity: 0.9;
}

/* .bgImgReviewPage::before {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    display: block;
    background-image: linear-gradient(to top, #fff8ff 5%, transparent 50%);
  } */
body {
  font-family:"Open Sans" ;
}

.container {
  background: linear-gradient(180deg, #fff8ff, #fff, rgb(255, 255, 255));
  /* background-color: #fff; */
  background-repeat: no-repeat;
  min-height: 100vh;
  margin: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: transparent;
}

.logo {
  width: 100px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li a {
  text-decoration: none;
  color: #000;
}

.sign-up-button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.breadcrumb {
  margin: 20px 0;
}

.breadcrumb a {
  text-decoration: none;
  color: #000;
}

.content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.text-content {
  max-width: 50%;
}

.course-tag {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 10px;
  font-family:"Open Sans" ;
}

.highlight {
  font-family: "Open Sans" ;
  color: #e91e63;
  background: -webkit-gradient(
    linear,
    left,
    right,
    from(#ff8a00),
    to(#e52e71)
  );
  background: linear-gradient(90deg, #0075c9, #8f128e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
};

.features-list {
  padding: 0;
}

.features-list li {
  margin: 10px 0;
}

.buttons {
  margin-top: 20px;
}

.apply-button, .download-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.apply-button {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

.download-button {
  background-color: red;
  color: white;
}

.image-content .main-image {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
}

.gradient-heading {
  font-size: 2.25rem;
  font-weight: 600;
  display: inline;
  font-family: "Open Sans";
  letter-spacing: -.003em;
  line-height: 1.0834933333;
  background: linear-gradient(90deg, #0075c9, #8f128e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
}

.gradient-heading-sm {
  font-size: 1.5rem;
  font-weight: 600;
  display: inline;
  font-family: "Open Sans";
  letter-spacing: -.003em;
  line-height: 1.0834933333;
  background: linear-gradient(90deg, #0075c9, #8f128e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
  margin: auto;
}

.only-heading {
    font-size: 2.25rem;
    font-weight: 600;
    font-family: "Open Sans";
    letter-spacing: -.003em;
    line-height: 1.0834933333;
}

.only-heading-sm {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Open Sans";
  letter-spacing: -.003em;
  line-height: 1.0834933333;
  margin: auto;
}

.small-heading {
  font-size:0.875rem;
  font-weight: 700;
  color:#e5097f;
  background: linear-gradient(90deg, #0075c9, #8f128e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
  padding-bottom: 10px;
}

@media only screen and (max-width: 555px) and (min-width: 100px)
{
  #zsiq_chat_wrap {
      height: 80% !important;
      width: 80% !important;
      right: 0 !important;
      left: auto !important;
  }
}
/* Custom styling for Zoho SalesIQ chat widget
@media (max-width: 554px) {
  .chat-iframe-wrap,
  .chat-loader-cont {
      transform: scale(0.3);
      transform-origin: bottom right;
  }
} */


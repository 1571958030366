.landing-page {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background-color: #333;
  color: #fff;
  padding: 1em;
  text-align: center;
}

header nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

header nav li {
  margin-right: 20px;
}

header nav a {
  color: #fff;
  text-decoration: none;
}

.logo {
  margin-top: 10px;
}

.hero {
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.hero-content {
  text-align: center;
}

.hero-content h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 24px;
  margin-bottom: 40px;
}

.hero-content button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}

.features {
  background-color: #f7f7f7;
  padding: 2em;
}

.feature-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.feature-card {
  background-color: #fff;
  padding: 20px;
  margin: 20px;
  width: 250px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.feature-card h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 18px;
  margin-bottom: 20px;
}

.testimonials {
  background-color: #fff;
  padding: 2em;
}

.testimonial-grid {
  display: flex;
}